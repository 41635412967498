/* 
-----------------------------------
Shapes Styles
-----------------------------------
 */
/* Squares */
.riskSquareNeutral {
    height: 40px;
    max-width: 40px;
    background-color: #bbb;
    border-radius: 5%;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 50px;
    font-size: small;
    display: flex;
    align-items: center;
    justify-content: center;
}

.riskSquareNothing {
    max-width: 35px;
    max-height: 35px;
    background-color: rgb(107, 150, 107, 0.5);
    border-radius: 5%;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 50px;
    font-size: small;
    display: flex;
    align-items: center;
    justify-content: center;
}

.riskSquareNothing {
    max-width: 35px;
    max-height: 35px;
    background-color: rgb(107, 150, 107, 0.5);
    border-radius: 5%;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 50px;
    font-size: small;
    display: flex;
    align-items: center;
    justify-content: center;
}

.riskSquareLow {
    max-width: 35px;
    max-height: 35px;
    background-color: green;
    border-radius: 5%;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 50px;
    font-size: small;
    display: flex;
    align-items: center;
    justify-content: center;
}

.riskSquareMid {
    max-width: 35px;
    max-height: 35px;
    background-color: yellow;
    border-radius: 5%;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 50px;
    font-size: small;
    display: flex;
    align-items: center;
    justify-content: center;
}

.riskSquareHigh {
    max-width: 35px;
    max-height: 35px;
    background-color: red;
    border-radius: 5%;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 50px;
    font-size: small;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Circles */
.riskDotNeutral {
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
}

.riskDotLow {
    height: 25px;
    width: 25px;
    background-color: green;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
}

.riskDotMid {
    height: 25px;
    width: 25px;
    background-color: yellow;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
}

.riskDotHigh {
    height: 25px;
    width: 25px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
}

.riskDotNothing {
    height: 25px;
    width: 25px;
    background-color: rgb(107, 150, 107, 0.5);
    border-radius: 50%;
    display: inline-block;
    text-align: center;
}

/* 
-----------------------------------
Navigation Styles
-----------------------------------
 */
nav {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(0, 44, 80);
    box-shadow: 0 5px 10px #ccc;
    font-size: 26px;
}

/* Basic styles for nav links */
nav a {
    display: flex;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    height: 100%;
    text-decoration: none;
}

a:hover {
    color: rgb(173, 173, 173);
    text-decoration: none;
}

/* Specific styles for non-active links */
.link {
    color: white;
}

/* Specific styles for active links */
.link-active {
    color: rgb(255, 255, 255);
    background: rgb(100, 76, 23);
}

.link-inactive {
    color: rgb(124, 124, 124);
    background: rgb(0, 44, 80);
}

.link-inactive:hover {
    color: rgb(124, 124, 124);
    background: rgb(0, 44, 80);
}

.content {
    margin-top: 50px;
    text-align: center;
}

.nav-bar-container {
    top: 0px;
    width: 100%;
}

/*
-----------------------------------
Bootstrap Customization
-----------------------------------
*/

/* DropdownButton fullwidth */

#objectDropdown {
    text-align: left;
    width: 100%;
}

#objectDropdown::before {
    display: inline-block;
    margin-right: 0.51em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

#objectDropdown::after {
    display: inline-block;
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
}

#feststellungDropdown {
    text-align: left;
    width: 100%;
}

#feststellungDropdown::before {
    display: inline-block;
    margin-right: 0.51em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

#feststellungDropdown::after {
    display: inline-block;
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
}

/* Buttons: Fixed Button width */


/* Login form */
.loginForm {
    max-width: 700px;
    margin: 50px auto;
}

.loginForm .input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.loginForm .input-container input {
    padding: 10px 20px;
    border-radius: 8px;
    border-color: rgb(0, 44, 80);
}

.loginForm .input-container label {
    display: block;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: bold;
}

.loginForm .button-container input[type=submit]:hover {
    color: rgb(0, 44, 80);
    background: white;
}

.loginForm .button-container input[type=submit] {
    transition: all 0.3s ease;
    appearance: none;
    padding: 10px 20px;
    border-radius: 8px;
    border: 2px solid rgb(0, 44, 80);
    background: rgb(0, 44, 80);
    color: white;
    margin-left: 67%;
    display: inline-block;
    width: 33%;
    margin-top: 10px;
}

.loginForm .error-message {
    color: red;
    font-size: 12px;
    margin-top: 20px;
    display: block;
    padding: 10px 20px;
    border: 2px solid red;
    border-radius: 8px;
}

.disabled {
    color: grey;
    pointer-events: none;
}